//Ethereum Constants Start
// CONTRACTS
export const CONTRACTS = {
  LUFFY  : '0x54012cDF4119DE84218F7EB90eEB87e25aE6EBd7', 
  STAKING: '0x7c9921966a9822f218a483bd82d3186a5a6b3f3d',
  ROUTER : '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  WETH   : '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  USDT   : '0xdAC17F958D2ee523a2206206994597C13D831ec7'  
}

// EXPLORER VALUES
export const EXPLORERS = {
  ETH: "https://etherscan.io"
}

// NETWORKS
export const CHAINS = {
  ERC: {
    id: 1,
    label: 'Ethereum',
    platform: 'ethereum',
    trustWallet: 'ethereum',
    color: '#21325B',
    rpc: 'https://mainnet.infura.io/v3/c718bab1b14b41f0a709dd4148944ad7',
  },
};

// UNISWAP BUY URL
export const UNISWAP = "https://app.uniswap.org/#/swap?outputCurrency=0x54012cdf4119de84218f7eb90eeb87e25ae6ebd7";