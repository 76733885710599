import React from 'react';
import styled from 'styled-components';

const Button = ({
  onClick, 
  children, 
  disabled, 
  block, 
  type, 
  size
}) => {

  return (
    <StyledButton 
      disabled={disabled} 
      onClick={onClick}
      size={size}
      type={type}
      block={block}
    >
      {children}
    </StyledButton>
  )
}

export const ButtonLink = ({
  href, 
  children, 
  block, 
  type, 
  size
}) => {

  return (
    <StyledLink 
      href={href}
      size={size}
      type={type}
      block={block}
    >
      {children}
    </StyledLink>
  )
}

const size = (props) => {
  let styles;

  switch(props.size) {
    case "large": styles = {height: "48px", fontSize: "14px", padding: "0 48px"}; break; 
    case "small": styles = {height: "32px", fontSize: "10px", padding: "0 8px"}; break; 
    default: styles = {height: "40px", fontSize: "12px"};  
  }

  return styles; 
}

const buttonType = (props) => {
  let styles;
  const {primary, swatch, background, backgroundTertiary, border, colorLight, color} = props.theme; 

  switch(props.type) {
    case "primary": styles = {background: primary, color: swatch.white}; break; 
    case "secondary": styles = {background: backgroundTertiary, color: color}; break; 
    case "outline": styles = {background: background, color: primary, border: `1px solid ${primary}`}; break; 
    case "subtle": styles = {background: background, color: colorLight, border: `1px solid ${border}`}; break; 
    case "border": styles = {background: 'none', color: `${swatch.white} !important`, border: `2px solid ${swatch.white}`}; break; 
    case "light": styles = {background: swatch.white, color: primary}; break; 
    default: styles = {background: primary, color: swatch.white};  
  }

  return styles; 
}

const StyledButton = styled.button`
  background: ${props => props.theme.primary};
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => props.theme.swatch.white};
  cursor: pointer;
  font-weight: bold;
  padding: 0 24px;
  text-transform: uppercase;
  ${buttonType}
  ${size}
  width: ${props => props.block ? "100%": "auto"};
  
  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    padding: 0 8px;
  }

  &:disabled {
    background: ${props => props.theme.border};
    color: ${props => props.theme.colorLight};
    opacity: .56;
  }
`

const StyledLink = styled.a`
  align-items: center;
  background: ${props => props.theme.primary};
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => props.theme.swatch.white};
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding: 0 24px;
  text-transform: uppercase;
  ${buttonType}
  ${size}
  width: ${props => props.block ? "100%": "auto"};
  
  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    padding: 0 8px;
  }

  &:disabled {
    background: ${props => props.theme.border};
    color: ${props => props.theme.colorLight};
    opacity: .56;
  }
`


export default Button; 