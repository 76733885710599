import Web3 from 'web3';
import StakeABI from '../contracts/stake.json';
import LuffyABI from '../contracts/luffy.json';
import UniswapABI from "../contracts/uniswap.json";

import { message } from 'antd';
import { CONTRACTS, CHAINS, EXPLORERS } from './constants';
import BN from 'bignumber.js';


/* GET GAS PRICE 
*
*  Description: fetches the the current gas price in gwei
*/ 
export const getGasPrice = async (setGasPrice) => {
  const web3 = new Web3(CHAINS.ERC.rpc);

  new web3.eth.getGasPrice((e, gasPriceInWei) => {  
    const priceInWei = gasPriceInWei ? gasPriceInWei : "" ;
    const gasPrice = Web3.utils.fromWei(priceInWei, 'gwei'); 
    setGasPrice(gasPrice);
  });
}

/* GET DOLLAR VALUE OF TOKEN 
*
*  Description: fetches the the current gas price in gwei
*/ 
export const getDollarValue = async (amount, setValue) => {
  const web3 = new Web3(CHAINS.ERC.rpc);
  const uniswapContract = new web3.eth.Contract(UniswapABI, CONTRACTS.ROUTER);
  const addressIn = CONTRACTS.LUFFY;
  const Weth = CONTRACTS.WETH;
  const addressOut = CONTRACTS.USDT;
  const swapAmount = new BN((await uniswapContract.methods.getAmountsOut(amount, [addressIn, Weth, addressOut]).call())[2]).dividedBy(new BN(10**6)).toString(10);
  setValue(swapAmount);
}


/* FETCH CONTRACTS 
*
*  Description: fetches the staking and luffy contracts so data can be pulled 
*/ 
export async function get_contracts(provider) {
  const web3 = new Web3(provider);
  return {
    staking: new web3.eth.Contract(StakeABI, CONTRACTS.STAKING),
    luffy: new web3.eth.Contract(LuffyABI, CONTRACTS.LUFFY),
  };
}


/* CALCULATE STAKING REWARDS 
*
*  Description: calculates rewards based on the pool and how much you are staking 
*/ 
export const calculate_rewards = async ({ poolId, stakeAmount, provider }) => {
  // CHECK FOR VALID AMOUNT FIRST
  if(stakeAmount && stakeAmount > 0) {
    const { staking } = await get_contracts(provider);
    return await staking.methods.calcStakeMaxReward(poolId, stakeAmount.toString(10)).call();
  }
  else {
    return 0; 
  }
};


/* GET POOL DATA 
*
*  Description: Fetches staking pool data. 
*/ 
export const getPoolData = async (staking) => {
  let pools = [];
  const poolsData = await staking.methods.allPools().call();

  // LOOP THROUGH AND CREATE DATA COLLECTION
  for (let i = 0; i < poolsData.length; i++) {
    let item = poolsData[i];

    pools.push({
      id: i,
      lockDays: item["lockDays"],
      rewardRate: item["rewardRate"],
      isFlexible: item["isFlexible"],
      totalStaked: item["totalStaked"],
      totalRewardsReserved: item["totalRewardsReserved"]
    });
  }

  // RETURN POOL DATA
  return pools; 
}


/* GET STAKING DATA 
*
*  Description: Fetches all staking deposits 
*/ 
const getStakingData = async (staking, account) => {
	  let stakes = [];
	  const stakingData = await staking.methods.getStakeInfoList(account).call();

    for (let i = 0; i < stakingData.length; i++) {
      let item = stakingData[i];

      stakes.push({
        amount: item["amount"],
        amountRewarded: item["amountRewarded"],
        isActive: item["isActive"],
        isMature: item["isMature"],
        lockDays: item["lockDays"],
        maturityTimestamp: item["maturityTimestamp"],
        poolID: item["poolID"],
        rewardRate: item["rewardRate"],
        stakeId: item["stakeId"],
        startTimestamp: item["startTimestamp"],
        unusedReservedReward: item["unusedReservedReward"],
        withdrawableReward: item["withdrawableReward"]
      });
	  }

    // RETURN STAKING DATA
    return stakes; 
}


/* GET ERROR MESSAGE 
*
*  Description: GETS ERROR MESSAGE FROM ResultStruct[]
*/ 
const getErrorMessageFromContractResult = (error) => {
  if(!error || !error.message) {
    return null;
  }
	
  let message = error.message;
  let index = message.indexOf('{');

  // MESSAGE RETURNED AS A DIRTY STRING WITH OBJECT. 
  // WE PARSE THE OBJECT FROM THE STRING IF IT EXISTS
	if(index !== -1) {
		try {
      // GET READABLE JSON OBJECT
			let jsonResult = message.substring(index, message.length);
			let jsonObject = JSON.parse(jsonResult);

      // CHECK FOR OBJECT 
			if(jsonObject && jsonObject.originalError && jsonObject.originalError.message) {
				let errorMessage = jsonObject.originalError.message;

        // CLEAN MESSAGE FROM 'excution reverted' PREFIX 
				if(errorMessage.indexOf('execution reverted: ') === 0) {
					errorMessage = errorMessage.substring('execution reverted: '.length).trim();
				}

				return errorMessage;
			}
		} 
    catch(error) {
      // NOTHING DISPLAYED JUST CAUGHT
    }
   } 
   else {
     // IN THIS CASE WE DID NOT NEED TO PARSE THE JASON OBJECT
     // WE SIMPLY CLEAN THE MESSAGE AND SHOW IT TO THE USER
     if(message.indexOf('execution reverted: ') === 0) {
       message = message.substring('execution reverted: '.length).trim();
     }
   }

   return message;
}


/* STAKE TOKENS
*
*  Description: Contract call for staking tokens.
*/ 
const stakeTokens = async (staking, account, decimals, poolId, stakeAmount, onProcessing, onReceipt, onStartCallback) => {
  return staking.methods
  .beginStake(poolId, new BN(stakeAmount).multipliedBy(new BN(10 ** decimals)).toString(10))
  .send({ from: account })
  .on('transactionHash', tx => {
    onProcessing({ tx, base: EXPLORERS.ETH }); 
    
    //OPTIONAL METHOD TO FIRE WHEN TRANSACTION BEGINS
    if(onStartCallback) {
      onStartCallback(); 
    }
  })
  .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: EXPLORERS.ETH }))
  .on('error', async error => {
    const errMessage = getErrorMessageFromContractResult(error) || 'An error ocurred'; 
    message.error(errMessage);
  });
}


/* GET INFO OBJECT
*
*  Description: Fetches information about pools for an account.
*  Returns: An object with all pool & user data including staking/unstake methods 
*/ 
export const get_info = async (provider, account) => {
  try {
    // FETCH CONTRACTS 
    const { staking, luffy } = await get_contracts(provider);

    // FETCH POOL DATA
    const pools = await getPoolData(staking); 
	
	  // FETCH STAKING DATA
	  const stakes = await getStakingData(staking, account); 
    const stakeCount = stakes.length; 
	
    // GET ACCOUNT AND CONTRACT INFO
    const decimals = await luffy.methods.decimals().call();
    const rawBalance = await luffy.methods.balanceOf(account).call(); 
    const allowance = new BN(await luffy.methods.allowance(account, CONTRACTS.STAKING).call()).dividedBy(new BN(10 ** decimals)).toString(10);
    const balance = new BN(rawBalance).dividedBy(new BN(10 ** decimals)).toString(10);

    // RETURN DATA AS AN OBJECT 
    const info = {
      pools,
      decimals,
      stakeCount,
      stakes,
      allowance,
      balance,
      rawBalance,
      approve: async ({ onProcessing, onReceipt }) => {
        return luffy.methods
          .approve(CONTRACTS.STAKING, window.BigInt(2 ** 250))
          .send({ from: account, gas: 100000 })
          .on('transactionHash', tx => onProcessing({ tx, base: EXPLORERS.ETH }))
          .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: EXPLORERS.ETH }))
          .on('error', async error => {
            const errMessage = getErrorMessageFromContractResult(error) || 'An error ocurred'; 
            message.error(errMessage);
          });
      },
      stake: async ({ poolId, stakeAmount, onProcessing, onReceipt, onStartCallback }) => {
        //VALIDATE TRANSACTION FIRST
        return staking.methods
          .beginStake(poolId, new BN(stakeAmount).multipliedBy(new BN(10 ** decimals)).toString(10))
          .call({ from: account })
          .then(data => {
            stakeTokens(staking, account, decimals, poolId, stakeAmount, onProcessing, onReceipt, onStartCallback); 
          })
          .catch(error => {
            const errMessage = getErrorMessageFromContractResult(error) || 'An error ocurred'; 
            message.error(errMessage);
          });
      },
      unstake: async ({ stakeId, onProcessing, onReceipt }) => {
        // VALIDATE FIRST 
        return staking.methods
          .endStake(stakeId)
          .call({ from: account })
          .then(data => {
            staking.methods
                .endStake(stakeId)
                .send({ from: account })
                .on('transactionHash', tx => onProcessing({ tx, base: EXPLORERS.ETH }))
                .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: EXPLORERS.ETH }))
                .on('error', async error => {
                  const errMessage = getErrorMessageFromContractResult(error) || 'An error ocurred'; 
                  message.error(errMessage);
                });
          })
          .catch(error => {
            const errMessage = getErrorMessageFromContractResult(error) || 'An error ocurred'; 
            message.error(errMessage);
          });
      },
    };

    return info;
  } 
  catch (err) {
    return false;
  }
};
