import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import logo from '../assets/images/logos/luffy-small.svg';

const TopNav = ({
  transparent, 
  pinned, 
}) => {

  return (
    <StyledNav transparent={transparent} pinned={pinned}>
      <StyledLogo to="/" transparent={transparent}>
        <img src={logo} alt="Luffy Token" />
        Luffy Token
      </StyledLogo>
    </StyledNav>
  )
}

const pinned = (props) => {
  if(props.pinned) {
    return {
      top: 0, 
      left: 0, 
      right: 0, 
      position: "absolute"
    }
  }
}

const StyledNav = styled.nav`
  align-items: center;
  background: ${props => props.transparent ? "none" :  props.theme.background};
  box-shadow: ${props => props.transparent ? "none" :  props.theme.shadow.small};
  box-sizing: border-box;
  color: ${props => props.theme.color};
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 80px;
  position: relative;
  z-index: ${props => props.theme.layer.z6};
  ${pinned};

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    padding: 0 16px;
  }
`

const StyledLogo = styled(Link)`
  align-items: center;
  color: ${props => props.transparent ? props.theme.swatch.white : props.theme.color};
  font-size: 22px;
  font-weight: 900;
  line-height: 80px;
  height: 80px;
  display: flex;
  text-decoration: none;

  &:hover {
    color: ${props => props.transparent ? props.theme.swatch.white : props.theme.color};
  }

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    font-size: 18px;
  }

  img {
    margin-right: 16px;
    width: 48px;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      margin-right: 8px
    }
  }
`

export default TopNav; 