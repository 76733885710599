import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import ScaleLoader from "react-spinners/ScaleLoader";


const BlankstateCard = ({
  height = "400px"
}) => {
  const theme = useContext(ThemeContext)

  return (
    <StyledBlankstate>
      <div className="fake-card" style={{height: height}}></div>
      <StyledLoadingIndicator>
        <ScaleLoader color={theme.background} size={24} />
      </StyledLoadingIndicator>
    </StyledBlankstate>
  )
}

const BlankstateForm = () => {
  const theme = useContext(ThemeContext)

  return (
    <StyledBlankstate>
      <div className="fake-label"></div>
      <ul className='fake-slugs'>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div className='fake-field'></div>

      <StyledLoadingIndicator>
        <ScaleLoader color={theme.background} size={24} />
      </StyledLoadingIndicator>
    </StyledBlankstate>
  )
}

const StyledLoadingIndicator = styled.div`
  align-items: center;
  color: ${props => props.theme.primary}; 
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0; 
  left: 0;
  bottom: 0;
  right: 0;
`

const StyledBlankstate = styled.div`
  position: relative;
  text-align: center;

  .fake-card {
    background-color: ${props => props.theme.border};
    border-radius: 8px;
    height: 400px;
    margin: 0;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      background: none;
      height: auto;
      padding: 40px;
    }
  }
  
  .fake-slugs {
    margin: 0; 
    padding: 0;
    list-style: none;

    li {
      background-color: ${props => props.theme.border};
      border-radius: 8px;
      height: 80px;
      margin: 0 0 8px 0;
    }
  }

  .fake-field {
    background-color: ${props => props.theme.border};
    border-radius: 8px;
    height: 40px;
    margin: 16px 0 0 0;
  }

  .fake-label {
    background-color: ${props => props.theme.border};
    border-radius: 4px;
    height: 16px;
    margin: 0 0 8px 0;
  }
`

export {
  BlankstateForm, 
  BlankstateCard
}