import React from 'react';
import DefaultLayout from '../../layouts/default';
import Hero from './hero';
import TopNav from '../../components/top-nav';
import LogoBar from './logo-bar';
import StakingInfo from './staking-info';
import ContactUs from './contact';
import Buy from './buy';

const HomePage = () => {
  return (
    <DefaultLayout>
      <TopNav pinned="true" transparent="true" /> 
      <Hero /> 
      <LogoBar /> 
      <StakingInfo />
      <ContactUs /> 
      <Buy />  
    </DefaultLayout>
  );
}

export default HomePage; 