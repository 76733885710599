import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import _ from "lodash"; 
import { calculate_rewards, getPoolData, get_contracts } from '../../utils/api';
import { CHAINS } from '../../utils/constants';

import Section from '../../layouts/section';
import Button from '../../components/button';
import ConnectModal from '../../components/connect-modal';
import FakePoolButton from './fake-pool-button';
import Card from '../../components/card';

const StakingInfo = () => {
  const [visible, setVisible] = useState(false);
  const [poolId, setPoolId] = useState(4);
  const [stakeAmount, setStakeAmount] = useState("0");
  const [estimatedRewards, setEstimatedRewards] = useState(null);
  const [hasError, setFormError] = useState(false);
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [pools, setPoolData] = useState([]);

  // SET VARS
  const maxSize = Number.MAX_SAFE_INTEGER; 
  let rewardsInfo = "0"; 

  // CHECK IF THERE IS AN ESTIMATED REWARDS
  if(estimatedRewards !== null) {
    rewardsInfo = loadingRewards ? "Calculating..." : `${numeral(estimatedRewards).format('0,0')} Luffy`
  }


  // VALIDATE FORM INPUT
  const validateFormInput = (e) => {
    validateAndSetAmount(e.target.value);
  }
  
  // VALIDATE USER INPUT
  const validateAndSetAmount = (amount) => {
    const userInput = parseInt(amount, 10); 
    const isValid = _.isNumber(userInput) && _.inRange(userInput, 0, maxSize);

    // EMPTY VALUE
    if(amount === "") {
      setStakeAmount(''); 
    }
    // IF NUMBER IS VALID, SET AMOUNT
    else if(isValid) {
      setStakeAmount(userInput);
      setFormError(false); 
    }
    else {
      setFormError(true); 
    }
  }

  // GRAB POOL DATA AFTER GETTING STAKING INFO
  useEffect(() => {
    get_contracts(CHAINS.ERC.rpc).then(data => {
      // FETCH POOL INFO AN UPDATE POOL ARRAY
      if(data.staking) {
        getPoolData(data.staking).then(poolData => setPoolData(poolData)); 
      }
    });
  }, []); 

  // SET POOL ID & CALCULATE REWARDS 
  useEffect(() => {
    // CHECK FOR A SET POOL ID & VALID AMOUNT
    if (poolId !== null && stakeAmount !== null && stakeAmount !== "") {

      // LOADING REWARDS STATE
      setLoadingRewards(true);

      // FETCH REWARDS ESTIMATE
      calculate_rewards({
        provider: CHAINS.ERC.rpc,
        poolId,
        stakeAmount,
      }).then(rewards => {
        setLoadingRewards(false);
        setEstimatedRewards(rewards);
      });
    }; // eslint-disable-next-line
  }, [poolId, stakeAmount]); 

  return (
    <Section>
      <div className='grid-middle-center-reverse'>
        <div className='col-5_sm-12' data-push-left="off-1_sm-0">
          <h4>Luffy Staking</h4>
          <h3>Calculate Rewards</h3>
          <p>
            Stake your Luffy tokens and immediately begin earning rewards. 
            Luffy staking is currently available on the Ethereum Network. 
          </p>

          { estimatedRewards > 0 && 
            <h5 className='animate__animated animate__fadeIn'>Estimated Rewards: {rewardsInfo}</h5>
          }

          <Button size="large" type="primary" onClick={()=> {setVisible(true)}}>Start Staking</Button>
        </div>

        <div className='col-6_sm-12'>
          <Card>
            <StyledForm className='grid'>
              {pools.map(p =>  {
                const colSize = p.id === 4 ? "col-12" : "col-6_sm-12"; 

                return (
                  <div className={colSize} key={p.id} >
                    <FakePoolButton 
                      selectPool={setPoolId} 
                      pool={p} 
                      poolId={poolId}
                    />
                  </div>
                ); 
              })}

              {pools.length === 0 && 
                <div className='fake-form col-12'></div>
              }

              <div className='col-12'>
                {/* FORM INPUT FIELD */}
                <label className={`${hasError ? "is-error" : ""}`}>
                  {hasError ? "Enter a Valid Amount" : "Amount to Stake" }
                </label>
                <input 
                  max={maxSize} 
                  min={0} 
                  value={stakeAmount}
                  onChange={validateFormInput}
                />
                <label className='is-tip'>Estimated Rewards: {rewardsInfo}</label>
              </div>
            </StyledForm>
          </Card>
        </div>
      </div>

      <ConnectModal visible={visible} setVisible={setVisible} /> 
    </Section>
  )
}



const StyledForm = styled.div`
  .fake-form {
    background-color: ${props => props.theme.backgroundSecondary};
    border-radius: 8px;
    display: block;
    height: 262px;
    opacity: .56;
    margin: 0 0 8px 0;
  }

  label {
    color: ${props => props.theme.color};
    display: block;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
    position: relative;

    &.is-error {
      color: ${props => props.theme.error};
    }
    
    button {
      background: none;
      border: none;
      color: ${props => props.theme.primary};
      cursor: pointer;
      outline: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 16px;
      text-decoration: underline;
    }

    &.is-tip {
      color: ${props => props.theme.swatch.green};
      font-weight: bold;
      margin: 8px 0 0 0;
    }
  }

  input {
    background: ${props => props.theme.formBackground};
    border: 1px solid ${props => props.theme.border};
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .12);
    color: ${props => props.theme.color};
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 0 16px;
    width: 100%;

    &:focus {
      border: 1px solid ${props => props.theme.primary};
    }
  }

  footer {
    padding-top: 24px;
  }
`

export default StakingInfo; 