import React, { useContext, useEffect, useState} from 'react';
import _ from "lodash"; 
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { WalletContext } from '../index.js';

// NEW COMPONENTS 
import StakingTable from './table';
import Button from '../../button';
import Layout from './layout';
import ProfilePanel from './profile-panel';
import StakingPanel from './staking-panel';
import LuffyLogo from "../../../assets/images/logos/luffy-small.svg";
import StakingModal from './staking-modal';
import Approve from './approve.js';
import { BlankstateCard } from './blankstate.js';

const StakingApp = ({setTheme}) => {
  const { account } = useWeb3React();
  const { disconnect, info } = useContext(WalletContext);
  const [deposits, setDeposits] = useState(null);
  const [createVisible, setCreateVisible] = useState(false);
  const depositInfo = deposits ? <p>You have {deposits.length} active deposits</p> : null; 
  let content = <BlankstateCard />; 
  let stakeButton = null; 

  // WALLET NOT YET APPROVED 
  if(info && info.allowance <= 0) {
    content = <Approve />; 
  }

  // SHOW STAKING TABLE
  if(info && info.allowance > 0) {
    content = <StakingTable showStakingModal={setCreateVisible} />;
    stakeButton = <Button onClick={() => {setCreateVisible(true)}} type="primary">Stake Luffy</Button>; 
  }

  // GET ACTIVE DEPOSITS
  useEffect(() => {
    if(info) {
      let activeDesposits = _.filter(info.stakes, { 'isActive': true });
      setDeposits(activeDesposits); 
    }
  }, [info]);

  return (
    <Layout>
      <ProfilePanel setTheme={setTheme} contract={account} info={info} disconnect={disconnect} />

      <StyledHeader>
        <img src={LuffyLogo} alt="Luffy Token" width="45px"/> 
        <div className='title-info'>
          <h1>Luffy Staking Dashboard</h1>
          {depositInfo}
        </div>
        {stakeButton}
      </StyledHeader>

      {content}

      <StakingPanel WalletContext={WalletContext} />
      <StakingModal {...{ createVisible, setCreateVisible }} />
    </Layout>
  ); 
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  position: relative;

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    display: block;
    padding: 0 24px;
    margin: 0;
  }

  img {
    margin-right: 16px; 

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      display: none;
    }
  }

  .title-info {
    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      display: none;
    }
  }

  h1 {
    color: ${props => props.theme.color}; 
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
    margin: 0;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      font-size: 18px;
    }
  }

  p {
    color: ${props => props.theme.color}; 
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    opacity: .56;
  }

  button {
    display: none;
    position: absolute;
    right: 0;

    @media only screen and (max-width: ${props => props.theme.mobile.tablet}) {
      display: inline-block;
    }

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      clear: both;
      display: block;
      position: relative;
      margin: 24px 0 0 0;
      width: 100%;
    }
  }
`

export default StakingApp; 
