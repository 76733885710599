import React, {useContext} from 'react';
import { WalletContext } from '../index';
import styled from 'styled-components';
import BlankstateImage from "../../../assets/images/blankstate/approve.png";
import Card from '../../card.js';
import Button from '../../button';

const Approve = () => {
  const { info, onProcessing, onReceipt } = useContext(WalletContext);

  return (
    <Card>
      <StyledApprove>
        <h3>Lets Get Started!</h3>
        <figure>
          <img src={BlankstateImage} alt="Approve to Stake Luffy" /> 
          <figcaption>
            Please click on the button below and approve Luffy for staking. 
            Once you have approved your wallet, you can begin staking Luffy and earn rewards! 
          </figcaption>
        </figure>

        <Button size="large" onClick={() => {info.approve({ onProcessing, onReceipt })}} block>
          Approve Luffy for Staking
        </Button>
      </StyledApprove>
    </Card>
  );
};

const StyledApprove = styled.div`
  h3 {
    color: ${props => props.theme.color};
    font-size: 24px;
    font-weight: 900;
    line-height: 32px;
  }

  figure {
    margin-bottom: 24px;
  }

  img {
    border-radius: 16px;
    width: 100%;
    max-width: 100%;
  }

  figcaption {
    color: ${props => props.theme.color};
    font-size: 16px;
    display: block;
    line-height: 24px;
    margin: 16px 0 0 0; 
  }
`

export default Approve; 