import React, { useContext } from 'react';
import { ethers } from 'ethers';
import moment from 'moment';
import numeral from 'numeral';
import styled, {useTheme} from 'styled-components';
import { WalletContext } from '../index.js';
import { Popconfirm, Progress } from 'antd';
import Button from '../../button.js';
import Alert from '../../alert.js';

/* HELPER METHODS
* 
* description: formats values into human readable numbers 
* 
*/ 

const formatNumber = (num, decimals) => {
  let staked = 0; 

  if (num) {
    staked = (+ethers.utils.formatUnits(`${num}`, decimals)).toFixed(2); 

    staked = numeral(staked).format('0,0');
  }

  return staked 
};


/* WITHDRAW BUTTON
* 
* description: used to withdraw active staking deposits 
* 
*/ 

const WithdrawButton = ({
  isMature, 
  isActive, 
  poolID, 
  unstake, 
  stakeId, 
  onProcessing, 
  onReceipt
}) => {
  let button = null;
  const isAvaiable = isMature || poolID === '0';
  const title = isAvaiable ? 'Would you like to withdraw rewards?' : 'Are you sure? Your rewards are not ready and will be lost'; 
  const buttonType = isMature ? "primary" : "subtle"; 

  // WITHDRAW STAKING DEPOSIT
  const onConfirm = () => {
    unstake({stakeId: stakeId, onProcessing, onReceipt});
  }

  // IF STAKING IS ACTIVE ALLOW FOR WITHDRAW
  if (isActive) {
    button = (
      <Popconfirm okText="Yes, Withdraw" title={title} onConfirm={onConfirm} icon={null}>
        <Button type={buttonType} size="small" block>Withdraw</Button>
      </Popconfirm>
    )
  }

  // RETURN BUTTON
  return button; 
}


/* EARNED TABLE COLUMN
* 
* description: displays the amount of luffy earned during staking period
* 
*/ 

const getEarned = (stake, decimals, theme) => {
  const available = stake.isMature || stake.poolID === '0';
  const days = moment(stake.maturityTimestamp * 1000).diff(moment(), 'days');
  const maturity = moment(stake.maturityTimestamp * 1000).fromNow();
  const availableOn = days === 0 ? maturity : `in ${days} days`;
  const curTimestamp = new Date().getTime() / 1000;
  const diff1 = parseFloat(curTimestamp) - parseFloat(stake.startTimestamp);
  const diff2 = parseFloat(stake.maturityTimestamp) - parseFloat(stake.startTimestamp);
  const withdrawAmount = formatNumber(stake.withdrawableReward, decimals); 
  const rewards = stake.unusedReservedReward;
  const availability = !available && stake.isActive ? <em>Available {availableOn}</em> : null; 
  let earned = ethers.BigNumber.from(stake.unusedReservedReward).mul(diff1.toFixed(0)).div(diff2.toFixed(0));
  let earnedContent = null; 

  if(stake.isMature && stake.isActive) {
    // STAKING IS COMPLETED
    earnedContent = <Alert type="success">Staking completed! {withdrawAmount} rewards earned. </Alert>; 
  }
  else if(!stake.isMature && stake.isActive){
    // STAKING IN PROGRESS
    earnedContent = (
      <>
        <strong>{formatNumber(earned, decimals)} of {formatNumber(rewards, decimals)} rewards earned.</strong>
        <Progress strokeColor={theme.primary} trailColor={theme.backgroundSecondary} percent={(100 * earned) / rewards} showInfo={false} size="small" />
      </>
    )
  }
  else if(!stake.isMature && !stake.isActive) {
    // STAKING WITHDRAW BEFORE MATURITY
    if(stake.poolID === '0' && stake.amountRewarded > 0) {
      // FLEXIBLE APR WITHDRAW
      earnedContent = <Alert type="warning">Withdrawn before staking completed. {formatNumber(stake.amountRewarded, decimals)} rewards claimed.</Alert>;
    } 
    else {
      // NORMAL STAKING EARLY WITHDRAW, LOSE ALL TOKENS
      earnedContent = <Alert type="warning">Withdrawn before staking completed.</Alert>; 
    }
  }
  else if(stake.isMature && !stake.isActive) {
    // STAKING WITHDRAWN AFTER MATURITY REACHED
    earnedContent = <Alert type="info">{formatNumber(stake.amountRewarded, decimals)} rewards claimed.</Alert>; 
  }

  return (
    <div>
      {earnedContent}    
      {availability}
    </div>
  );
};


/* STAKING TABLE ROW
* 
* description: this table row display information on a staking deposit
* 
*/ 

const Row = ({item}) => {
  const theme = useTheme(); 
  const {amount, isActive, isMature, poolID, stakeId, lockDays, rewardRate, startTimestamp} = item; 
  const { info, onProcessing, onReceipt } = useContext(WalletContext);
  const { pools, unstake, decimals } = info;

  const pool = pools.find(p => `${p.id}` === `${poolID}`);
  const poolType = pool.isFlexible ? 'Flexible' : `${lockDays} Day Lock`;
  const apr = `APR ${rewardRate}%`
  const status = isActive ? "is-active" : "is-inactive";

  return (
    <StyledRow key={startTimestamp} status={status}>
      <td>
        <h3>{formatNumber(amount, decimals)}</h3>
        <em>{poolType} - {apr}</em>
      </td>
      <td>{getEarned(item, decimals, theme)}</td>
      <td>
        <WithdrawButton
          isActive={isActive}
          isMature={isMature}
          poolID={poolID}
          unstake={unstake}
          stakeId={stakeId}
          onProcessing={onProcessing}
          onReceipt={onReceipt}
          block
        /> 
      </td>
    </StyledRow>
  );
}

const StyledRow = styled.tr`
  td {
    border-bottom: 1px solid ${props => props.theme.border};
    color: ${props => props.theme.color};
    font-size: 14px;
    height: 80px;
    text-align: left;
    padding: 0 16px;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      padding: 8px;
    }

    h3 {
      color: ${props => props.theme.color};
      font-size: 14px;
      font-weight: bold;
      line-height: 24px;
      margin: 0;

      @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
        font-size: 12px;
      }
    }

    strong {
      color: ${props => props.theme.color};
      font-size: 11px;
      font-weight: bold;
      display: block;
      line-height: 16px;
      margin: 0;
    }

    em {
      color: ${props => props.theme.colorLight};
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      display: block;
      line-height: 16px;
      margin: 0;
    }

    &:first-child {
      max-width: 168px;
      min-width: 168px;
      width: 168px;
      padding-left: 24px;

      @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
        max-width: auto;
        min-width: auto;
        width: auto;
        padding-left: 16px;
      }
    }

    &:last-child {
      max-width: 140px;
      min-width: 140px;
      width: 140px;
      padding-right: 24px;

      @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
        max-width: auto;
        min-width: auto;
        width: auto;
        padding-right: 16px;
      }
    }
  }
`

export default Row; 
