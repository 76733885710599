import React, {useContext} from 'react';
import styled from 'styled-components';
import StakingForm from './staking-form';
import GasPrice from './gas-price';

const StakingPanel = ({WalletContext}) => {
  const { info } = useContext(WalletContext);
  let content = null; 


  // SHOW STAKING TABLE
  if(info && info.allowance > 0) {
    content = <StakingForm WalletContext={WalletContext} /> 
  }
  
  return (
    <StyledPanel>
      <header>
        <span className="material-icons header-logo">currency_exchange</span>
        <div>
          <h3>Stake Luffy</h3>
          <h4>Ethereum Network</h4>
        </div>

        <GasPrice isPinned={true} /> 
      </header>
      
      {content}
    </StyledPanel> 
  )
}


const StyledPanel = styled.div`
  background: ${props => props.theme.background};
  box-shadow: ${props => props.theme.shadow.small};
  bottom: 0;
  box-sizing: border-box;
  overflow: auto;
  padding: 24px;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  z-index: ${props => props.theme.layer.z6};

  @media only screen and (max-width: ${props => props.theme.mobile.tablet}) {
    display: none;
  }

  header {
    display: flex;
    margin-bottom: 32px;
    position: relative;

    .header-logo {
      font-size: 40px;
      margin-right: 16px;
    }

    h3 {
      color: ${props => props.theme.color};
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
      margin: 0;
    }
    h4 {
      color: ${props => props.theme.colorLight};
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      margin: 0;
    }
  }
`

export default StakingPanel; 