import React, { useState, useEffect } from 'react';
import styled, {useTheme} from 'styled-components';
import Button, {ButtonLink} from '../../components/button';
import ConnectModal from '../../components/connect-modal';
import DarkDashboard from '../../assets/images/graphics/dashboard-dark.png';
import LightDashboard from '../../assets/images/graphics/dashboard-light.png';

const Hero = () => {
  const [visible, setVisible] = useState(false);
  const theme = useTheme(); 

  // HERO BACKGROUND ANIMATION
  useEffect(() => {
    window.VANTA.WAVES({
      el: "#hero",
      color: theme.primary,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      height: "100%",
      scale: 1.00,
      scaleMobile: 1.00,
      zoom: 0.69
    })
  }); 

  return (
    <StyledHero id='hero'>
      <div className='hero-inner'>
        <div className='grid'>
          <div className='hero-cta col-5_sm-12'>
            <h1>Stake Luffy<br />Earn Rewards</h1>
            <p>
              Luffy provides flexible and long term staking with an APR of up to 28%. 
              Stake your Luffy tokens today and start earning rewards. 
            </p>
            <div className='grid'>
              <div className='col-6_sm-12'>
                <Button block={true} size="large" type="light" onClick={()=> {setVisible(true)}}>Start Staking</Button>
              </div>
              <div className='col-6_sm-12'>
                <ButtonLink size="large" type="border" href="#buy">Buy Luffy</ButtonLink>
              </div>
            </div>
          </div>

          <div className='col-7_sm-12' style={{position: "relative"}}>
            <figure className='showcase animate__animated animate__fadeIn'>
              <img className='showcase-image-1' height="340" src={LightDashboard} alt="Light Staking Dashboard" /> 
              <img className='showcase-image-2' height="340" src={DarkDashboard} alt="Dark Staking Dashboard" /> 
            </figure>
          </div>
        </div>
      </div>
      
      
      <ConnectModal visible={visible} setVisible={setVisible} /> 
    </StyledHero>    
  );
}

const StyledHero = styled.header`
  background: ${props => props.theme.primary};
  color: ${props => props.theme.swatch.white};
  text-align: left;
  padding: 160px 80px 80px 80px;
  position: relative;

  .hero-inner {
    max-width: 1260px;
    margin: 0 auto;
  }

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    padding: 88px 16px 16px 16px;
    text-align: center;
  }

  .showcase {
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      position: relative;
      display: block;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }

    img {
      max-width: 100%;
      position: absolute;
      
      @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
        position: relative;
        width: 100%;
        height: auto;
      }
    }

    .showcase-image-1 {
      top: ${props => props.theme.name === "light" ?  'auto' : "-64px" }; 
      right: ${props => props.theme.name === "light" ?  'auto' : 0 };
      z-index: ${props => props.theme.name === "light" ?  props.theme.layer.z2 : props.theme.layer.z1 };

      @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
        top: auto;
        right: auto;
        min-height: 232px;
        display: ${props => props.theme.name === "light" ?  'block' : "none" };
      }
    }

    .showcase-image-2 {
      top: ${props => props.theme.name === "dark" ?  'auto' : "-64px" }; 
      right: ${props => props.theme.name === "dark" ?  'auto' : 0 };
      z-index: ${props => props.theme.name === "dark" ?  props.theme.layer.z2 : props.theme.layer.z1 };

      @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
        top: auto;
        right: auto;
        min-height: 232px;
        display: ${props => props.theme.name === "dark" ?  'block' : "none" };
      }
    }
  }

  h1 {
    color: ${props => props.theme.swatch.white};
    font-size: 64px;
    font-weight: 900;
    line-height: 72px;
    margin: 0 0 16px 0;
    text-shadow: 0 8px 32px rgba(0, 0, 0, .56);

    @media only screen and (max-width: ${props => props.theme.mobile.tablet}) {
      font-size: 56px;
      line-height: 56px;
    }

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      font-size: 48px;
      line-height: 56px;
    }
  }

  .hero-cta button, .hero-cta a {
    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      width: 90%;
      margin: 0 auto 24px auto;
    }
  }

  p {
    color: ${props => props.theme.swatch.white};
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 40px 0;
    text-shadow: 0 8px 32px rgba(0, 0, 0, .48);

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      font-size: 16px;
      line-height: 24px;
      padding: 0 16px;
    }
  }
`

export default Hero; 