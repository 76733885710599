import React from 'react';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';
import styled from 'styled-components';
import Button from '../../button';
import BalanceCard from './balance-card';
import ThemeToggle from './theme-toggle';
const colors = ['amber', 'blue', 'blueGrey', 'cyan', 'deepOrange', 'deepPurple', 'green', 'indigo', 'lightBlue', 'lightGreen', 'lime', 'orange', 'pink', 'purple', 'red', 'teal']; 

const ProfilePanel = ({
  contract, 
  disconnect, 
  info, 
  setTheme
}) => {

  const shortAddress = contract ? `${contract.slice(0, 6)}...${contract.slice(-4)}` : "no account"; 
  const balance = info && info.balance ? info.balance : ""; 
  const rawBalance = info ? info.rawBalance : ""; 
  let avatar = createAvatar(style, {seed: `${contract}`, dataUri: true, colors: colors});

  return (
    <StylePanel>
      <figure>
        <img src={avatar} alt={shortAddress} />
      </figure>
      <h3>{shortAddress}</h3>
      <BalanceCard balance={balance} rawBalance={rawBalance} /> 
      <ThemeToggle setTheme={setTheme} /> 
    
      <footer>
        <Button block type="outline" size="small" onClick={disconnect}>
          Disconnect
        </Button>
      </footer>
    </StylePanel> 
  )
}


const StylePanel = styled.div`
  background: ${props => props.theme.background};
  bottom: 0;
  box-sizing: border-box;
  box-shadow: ${props => props.theme.shadow.small};
  padding: 32px 24px;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  z-index: ${props => props.theme.layer.z6};

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    padding: 16px 24px 16px 120px;
    margin-bottom: 24px;
    width: auto;
  }

  figure {
    align-items: center;
    background: ${props => props.theme.border};
    border: 2px solid ${props => props.theme.primary};
    border-radius: 300px;
    box-shadow: ${props => props.theme.shadow.small};
    display: flex;
    justify-content: center;
    margin: 0 auto 16px auto;
    height: 64px;
    max-height: 64px;
    width: 64px;
    max-width: 64px;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      position: absolute;
      left: 24px;
      top: 24px;
      z-index: ${props => props.theme.layer.z1};
    }

    img {
      width: 80%;
    }
  }

  h3 {
    color: ${props => props.theme.color};
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    margin: 0;
    text-align: center;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      text-align: left;
      line-height: 32px;
    }
  }

  footer {
    bottom: 24px;
    left: 24px;
    position: absolute;
    right: 24px;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      bottom: auto;
      left: auto;
      position: absolute;
      right: 24px;
      top: 16px;

      button {
        height: 24px;
      }
    }
  }
`

export default ProfilePanel; 
