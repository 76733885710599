import React from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { injected, walletConnect, CoinbaseWallet } from '../utils/connectors';
import { CHAINS } from '../utils/constants';
import walletConnectLogo from '../assets/images/logos/walletconnect.png';
import metaMaskLogo from '../assets/images/logos/metamask.png';
import coinbaseLogo from '../assets/images/logos/cb-wallet.png';
import Modal from './modal';
import Alert from './alert';

export const ConnectModal = ({
  visible, 
  setVisible
}) => {
  const context = useWeb3React();
  const { activate } = context;
  let isWrongChain = false; 

  // CHECK FOR WRONG NETWORK IF METAMASK IS ENABLED 
  if(window.ethereum) {
    let network = parseInt(window.ethereum.networkVersion, 10); 
    isWrongChain = network !== CHAINS.ERC.id ? true : false; 
  }
  
  return (
      <Modal visible={visible} onClose={() => setVisible(false)} closeButton={true}>
          {isWrongChain && 
            <StyledNotice>
              <Alert type="error">Select Ethereum Mainet Network in your wallet</Alert>
            </StyledNotice>
          }
          <StyledButton onClick={() => {activate(injected)}}>
            <figure>
              <img src={metaMaskLogo} alt="WalletConnect"/>
              <figcaption>MetaMask</figcaption>
              <em>Connect to your MetaMask Wallet</em>
            </figure>
          </StyledButton>

          <StyledButton onClick={() => {activate(walletConnect);}}>
            <figure>
              <img src={walletConnectLogo}  alt="WalletConnect" />
              <figcaption>WalletConnect</figcaption>
              <em>Scan with WalletConnect to connect</em>
            </figure>
          </StyledButton>

          <StyledButton onClick={() => {activate(CoinbaseWallet);}}>
            <figure>
              <img src={coinbaseLogo}  alt="Coinbase" />
              <figcaption>Coinbase Wallet</figcaption>
              <em>Connect to your Coinbase Wallet</em>
            </figure>
          </StyledButton>
      </Modal>
  );
};

const StyledNotice = styled.div`
  margin-bottom: 16px;
  text-align: center;

  > div {
    padding: 16px;
    font-size: 16px;

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      font-size: 14px;
    }
  }
`

const StyledButton = styled.button`
  background: ${props => props.theme.background};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.border}; 
  cursor: pointer;
  display: block; 
  padding: 8px 16px;
  margin-bottom: 16px;
  transition: all .3s;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover, &:focus {
    background: ${props => props.theme.primaryLight};
    border: 1px solid ${props => props.theme.primary}; 
    box-shadow: ${props => props.theme.shadow.medium};

    figcaption {
      color: ${props => props.theme.primary};
    }
  }

  figure {
    display: block;
    text-align: center;
    margin: 0; 

    img {
      border-radius: 8px;
      max-width: 56px;
      margin-bottom: 8px;
    }

    figcaption {
      color: ${props => props.theme.color};
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }

    em {
      color: ${props => props.theme.colorLight};
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      line-height: 24px;
    }
  }
`

export default ConnectModal;