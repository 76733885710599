import React from 'react';
import styled from 'styled-components';

const Layout = ({children}) => {

  return (
    <StyledLayout>{children}</StyledLayout>
  ); 
} 

const StyledLayout = styled.div`
  background: ${props => props.theme.backgroundSecondary};
  padding: 32px 368px 32px 288px;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  z-index: 1;

  @media only screen and (max-width: ${props => props.theme.mobile.tablet}) {
    padding: 32px 32px 32px 288px;
  }

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    padding: 0px;
  }
`

export default Layout; 