import React, { useEffect, useState, createContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// HELPERS & CONSTANTS
import { get_info } from '../../utils/api';
import { EXPLORERS } from '../../utils/constants';

// COMPONENTS
import StakingApp from './app/';
import HomePage from '../../pages/home/';

// CREATE CONTEXT
export const WalletContext = createContext();

const Stake = ({setTheme}) => {
  const { active, account, library, deactivate } = useWeb3React();
  const [info, setInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const page = account ? <StakingApp setTheme={setTheme} /> : <HomePage /> 
  let toastId;

  // PROCESSING MESSAGE
  const onProcessing = ({ tx }) => {
    toastId = toast(<h3>Processing... <br/> <a target="_blank" rel="noopener noreferrer" href={`${EXPLORERS.ETH}/tx/${tx}`}>View Pending Transaction</a></h3>, {
      type: toast.TYPE.INFO, 
      position: "top-right",
      autoClose: false,
      closeOnClick: true,
      draggable: false
    })
  };

  // SUCCESS MESSAGE
  const onReceipt = ({ tx }) => {
    toast.update(toastId, {
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      render: <h3>Success! <br/> <a target="_blank" rel="noopener noreferrer" href={`${EXPLORERS.ETH}/tx/${tx}`}>View Transaction</a></h3> 
    });

    get_info(library.provider, account).then(setInfo);
  };

  // DISCONNECT 
  const disconnect = () => {
    deactivate();
    setInfo(null);
  };

  // GET STAKING INFO
  useEffect(() => {
    if(active && account) {
      // FETCH INITIAL PAYLOAD
      setLoadingInfo(true);
      get_info(library.provider, account).then(stakingInfo => {
        setInfo(stakingInfo);
        setLoadingInfo(false);
      });

      // NOW FETCH DATA EVERY 5 MINS
      setInterval(() => {
        setLoadingInfo(true);
        get_info(library.provider, account).then(stakingInfo => {
          setInfo(stakingInfo);
          setLoadingInfo(false);
        }); // eslint-disable-next-line
      }, 300000);
    } // eslint-disable-next-line
  }, [account, active]); 

  // OPTIONS FOR WALLET CONTEXT 
  const walletContextOptions = {
    wallet: account,
    disconnect,
    info,
    refetchInfo: () => get_info(library.provider, account).then(setInfo),
    clearInfo: () => setInfo(null),
    loadingInfo,
    onProcessing,
    onReceipt,
  }

  return (
    <WalletContext.Provider value={walletContextOptions}>
      <ToastContainer/>
      {page}
    </WalletContext.Provider>
  );
};

export default Stake;
