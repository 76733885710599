import React, {useEffect} from 'react';
import { BrowserRouter, useLocation } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import App from './App';

// CSS
import 'antd/dist/antd.css';
import "./assets/styles/grid/index.css"
import "./assets/styles/animate.css"

// GETT ROO CONTAINER
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// FORCE SCROLL TO TOP WHEN NAVIGATION TO DIFFERENT PAGES
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

root.render(
  <BrowserRouter>
    <ScrollToTop /> 
    <App />
  </BrowserRouter>
);