import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash"; 
import numeral from 'numeral';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { calculate_rewards } from '../../../utils/api';
import Button from '../../button';
import PoolButton from './pool-button';
import {BlankstateForm} from "./blankstate";


const StakingForm = ({WalletContext, onClose}) => {
  const { info, onProcessing, onReceipt } = useContext(WalletContext);
  const { library } = useWeb3React();

  // SET STATE FOR DATA
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [hasError, setFormError] = useState(false);
  const [estimatedRewards, setEstimatedRewards] = useState(null);
  const [poolId, setPoolId] = useState(0);
  const [stakeAmount, setStakeAmount] = useState("0");
  const [pools, setPools] = useState(null);
  const [balance, setBalance] = useState(null);
  const [decimals, setDecimals] = useState(null);

  // SET VARS
  const maxSize = Number.MAX_SAFE_INTEGER; 
  let rewardsInfo = "0"; 

  // CHECK IF SUBMIT BUTTON SHOULD BE DISABLED
  const isDisabled = poolId === null || stakeAmount === null || stakeAmount <= 0 ; 

  // CHECK IF THERE IS AN ESTIMATED REWARDS
  if(estimatedRewards !== null) {
    rewardsInfo = loadingRewards ? "Calculating..." : `${numeral(estimatedRewards).format('0,0')} Luffy`
  }

  // VALIDATE FORM INPUT
  const validateFormInput = (e) => {
    validateAndSetAmount(e.target.value);
  }
  
  const validateAndSetAmount = (amount) => {
    const userInput = parseInt(amount, 10); 
    const isValid = _.isNumber(userInput) && _.inRange(userInput, 0, maxSize);

    // EMPTY VALUE
    if(amount === "") {
      setStakeAmount(''); 
    }
    // IF NUMBER IS VALID, SET AMOUNT
    else if(isValid) {
      setStakeAmount(userInput);
      setFormError(false); 
    }
    else {
      setFormError(true); 
    }
  }

  // STAKE METHOD
  const onStake = () => {
    const hasPoolId = poolId !== null; 
    const hasStakeAmount = (stakeAmount > 0 && stakeAmount !== ""); 

    // METHOD FIRED WHEN TRANSACTION SUCCESSFULLY STARTED
    const onStartCallback = () => {
      // RESET STAKING FIELD 
      setStakeAmount("0"); 

      // CLOSE MODAL IF FORM IS EMBEDDED
      if(onClose) {
        onClose(); 
      }
    }

    // MAKE SURE POOL ID IS PRESENT AND AMOUNT IS SET
    if(hasPoolId && hasStakeAmount) {
      info.stake({ poolId, stakeAmount, onProcessing, onReceipt, onStartCallback }); 
    }
  }

  // SET DATA FROM INFO WHEN READY
  useEffect(() => {
    if(info) {
      setPools(info.pools); 
      setBalance(Math.trunc(info.balance)); 
      setDecimals(info.decimals); 
    }
  }, [info]);

  // SET POOL ID & CALCULATE REWARDS 
  useEffect(() => {
    // CHECK FOR A SET POOL ID & VALID AMOUNT
    if (poolId !== null && stakeAmount !== null && stakeAmount !== "") {
      
      // LOADING REWARDS STATE
      setLoadingRewards(true);

      // FETCH REWARDS ESTIMATE
      calculate_rewards({
        provider: library.provider,
        poolId,
        stakeAmount,
      }).then(rewards => {
        setLoadingRewards(false);
        setEstimatedRewards(rewards);
      });
    }; // eslint-disable-next-line
  }, [poolId, stakeAmount]); 

  // NO DATA YET, RETURN LOADING STATE
  if(!pools) {
    return <BlankstateForm /> 
  }

  // DATA READY, RENDER FORM
  return (
    <StyledForm>
      <div className='staking-buttons'>
        <label>Select a Staking Pool</label>
        
        {pools.map(p =>  {
          return (
            <PoolButton 
              key={p.id} 
              selectPool={setPoolId} 
              pool={p} 
              poolId={poolId}
              decimals={decimals} 
            />)
        })}
      </div>

      {/* FORM INPUT FIELD */}
      <label className={`${hasError ? "is-error" : ""}`}>
        {hasError ? "Enter a Valid Amount" : "Amount to Stake" }
        <button onClick={() => validateAndSetAmount(balance)}>Max</button>
      </label>
      <input 
        max={maxSize} 
        min={0} 
        value={stakeAmount}
        onChange={validateFormInput}
      />
      <label className='is-tip'>Estimated Rewards: {rewardsInfo}</label>

      <footer>
        <Button disabled={isDisabled} type="primary" block={true} onClick={onStake}>
          Stake Luffy
        </Button>
      </footer>
    </StyledForm> 
  )
}

const StyledForm = styled.div`
  .staking-buttons {
    margin-bottom: 24px;
  }
  
  label {
    color: ${props => props.theme.color};
    display: block;
    font-size: 11px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
    position: relative;

    &.is-error {
      color: ${props => props.theme.error};
    }
    
    button {
      background: none;
      border: none;
      color: ${props => props.theme.primary};
      cursor: pointer;
      outline: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 16px;
      text-decoration: underline;
    }

    &.is-tip {
      color: ${props => props.theme.swatch.green};
      font-weight: bold;
      margin: 8px 0 0 0;
    }
  }

  input {
    background: ${props => props.theme.formBackground};
    border: 1px solid ${props => props.theme.border};
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .12);
    color: ${props => props.theme.color};
    font-size: 14px;
    height: 40px;
    outline: none;
    padding: 0 16px;
    width: 100%;

    &:focus {
      border: 1px solid ${props => props.theme.primary};
    }
  }

  footer {
    padding-top: 24px;
  }
`


export default StakingForm; 
