import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { CHAINS } from './constants';

export const injected = new InjectedConnector({
  supportedChainIds: [CHAINS.ERC.id],
});

export const walletConnect = new WalletConnectConnector({
  rpc: {
    [CHAINS.ERC.id]: CHAINS.ERC.rpc,
  },
  projectId: "f2e8962256d73547658b7e9eebd37ffc",
  // bridge: "wss://relay.walletconnect.com",
  qrcode: true,
});

export const CoinbaseWallet = new WalletLinkConnector({
  url:  CHAINS.ERC.rpc,
  appName: "Luffy Token Staking",
  supportedChainIds: [CHAINS.ERC.id]
 });
