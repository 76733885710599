import React from "react";
import styled from "styled-components"; 

const FakePoolButton = ({
  pool,
  poolId,
  selectPool
}) => {
  const {id, isFlexible, lockDays, rewardRate} = pool; 
  const isSelected = pool.id === poolId ? true : false; 
  const lockDuration = isFlexible ? "Not Locked" : `Locked ${lockDays} Days`; 
  
  return (
    <StyledPoolButton selected={isSelected} onClick={() => selectPool(pool.id)}>
      <span className="apr">
        <strong>{rewardRate}%</strong>
        <em>APR</em>
      </span>

      <span className="pool-info">
        <em>Pool {id + 1}</em>
        <strong>{lockDuration}</strong>
      </span>
    </StyledPoolButton>
  ); 
}

const selectedStyles = (props) => {
  let styles = null; 

  if(props.selected) {
    styles = {
      background: `${props.theme.primaryLight}`,
      border: `1px solid ${props.theme.primary}`, 
      boxShadow: `${props.theme.shadow.small}`
    }
  }

  return styles; 
}

const StyledPoolButton = styled.button`
  background: ${props => props.theme.background}; 
  border-radius: 4px;
  border: 1px solid ${props => props.theme.border}; 
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex; 
  margin-bottom: 8px;
  padding: 8px;
  transition:  all .3s;
  width: 100%;
  ${selectedStyles}; 

  &:hover, &:focus {
    border: 1px solid ${props => props.theme.primary}; 
    box-shadow: ${props => props.theme.shadow.small};
  }

  .apr {
    background: ${props => props.selected ? props.theme.primary: props.theme.backgroundSecondary}; 
    border-radius: 300px;
    justify-content: center;
    margin-right: 16px;
    min-width: 56px;
    height: 56px;
    text-align: center;
    transition: all .3s;
    width: 56px;
    
    strong {
      color: ${props => props.selected ? props.theme.swatch.white: props.theme.primary}; 
      display: block;
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
      margin-top: 16px;
    }
    
    em {
      color: ${props => props.selected ? props.theme.swatch.white: props.theme.colorSecondary}; 
      display: block;
      font-size: 10px;
      font-style: normal;
      line-height: 16px;
      opacity: .56;
      text-transform: uppercase;
    }
  }

  .pool-info {
    color: ${props => props.selected ? props.theme.primary: props.theme.color}; 
    text-align: left;

    strong {
      display: block;
      font-size: 14px;
      font-weight: 900;
      line-height: 24px;
    }
    
    em {
      display: block;
      font-size: 10px;
      font-style: normal;
      line-height: 16px;
      margin-top: 8px;
      opacity: .56;
      text-transform: uppercase;
    }
  }
`


export default FakePoolButton; 