import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { getDollarValue } from '../../../utils/api';

const BalanceCard = ({
  balance, 
  rawBalance
}) => {
  const [dollarValue, setDollarValue] = useState(0);
  const [luffyBalance, setLuffyBalance] = useState("Loading Balance...");

  useEffect(() => {
    if(balance) {
      setLuffyBalance(numeral(balance).format('0,0')); 
    }
  }, [balance]); 

  useEffect(() => {
    if(rawBalance && rawBalance > 0) {
      getDollarValue(rawBalance, setDollarValue); 
    }
  }, [rawBalance, balance]); 

  return(
    <StyledBalance>
      <label>Luffy Balance (Eth)</label>
      <h4>{luffyBalance}</h4>
      <label>${numeral(dollarValue).format('0,0')}</label>
    </StyledBalance>
  );
}


const StyledBalance = styled.div`
  align-items: center;
  background: ${props => props.theme.backgroundSecondary};
  border-radius: 4px;
  box-shadow: inset ${props => props.theme.shadow.tiny} ;
  margin: 24px 0; 
  padding: 16px;

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    margin: 8px 0 0 0; 
  }

  label {
    color: ${props => props.theme.color};
    font-size: 11px;
    font-weight: bold;
    display: block;
    line-height: 16px;
    opacity: .87;
    text-transform: uppercase;
  }

  h4 {
    color: ${props => props.theme.primary};
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    margin: 0%;
  }

  .material-icons {
    font-size: 16px; 
    margin-right: 4px;
  }
`

export default BalanceCard; 