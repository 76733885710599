import React from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';



const WalletProvider = ({ children }) => {
  return (
    <Web3ReactProvider
      getLibrary={provider => {
        const library = new Web3Provider(provider);
        library.pollingInterval = 12000;
        return library;
      }}
    >
      {children}
    </Web3ReactProvider>
  );
};

export default WalletProvider;
