import React, {useState} from "react";
import { Routes, Route } from "react-router-dom";
import {ThemeProvider} from "styled-components"; 
import { darkTheme, lightTheme, GlobalStyles } from "./theme";
import Cookies from 'js-cookie';

// COMPONENTS
import WalletProvider from './WalletProvider';
import Stake from './components/staking/';
import TermsPage from "./pages/terms";

const App = () => {
  // CHECK IF USER HAS THEM SELECTED ALREADY
  const userTheme = Cookies.get("theme"); 
  const defaultTheme = userTheme || "light"; 
  const [theme, setTheme] = useState(defaultTheme); 

  return (
    <WalletProvider>
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyles /> 
        <Routes>
          <Route path="/" element={<Stake setTheme={setTheme} />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="*" element={<Stake setTheme={setTheme} />} />
        </Routes>
      </ThemeProvider>
    </WalletProvider>
  );
};

export default App;
