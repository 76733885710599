import React from 'react';
import styled from 'styled-components';


const Modal = ({
  children, 
  visible, 
  onClose, 
  closeButton = true
}) => {
  return (
    <StyledModal visible={visible}>
      <div className='overlay' onClick={onClose}></div>
      <div className='modal'>
        {closeButton && 
          <span className="material-icons modal-close" onClick={onClose}>close</span>
        }
        {children}
      </div>
    </StyledModal>
  );
}

const StyledModal = styled.div`
  align-items: center;
  display: ${props => props.visible ? "flex" : "none"};
  justify-content: center;
  overflow: auto;
  display: ${props => props.visible ? 1 : 0};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.theme.layer.z10};

  .overlay {
    background: ${props => props.theme.color};
    opacity: .24;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props => props.theme.layer.z1};
  }

  .modal {
    background: ${props => props.theme.background};
    box-shadow: ${props => props.theme.shadow.large};
    border-radius: 16px;
    box-sizing: border-box;
    color: ${props => props.theme.color};
    margin: 0 auto;
    max-width: 600px;
    padding: 32px;
    position: relative;
    width: 600px;
    z-index: ${props => props.theme.layer.z2};

    @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
      border-radius: 0;
      padding: 32px 16px 16px 16px;
    }
  }

  .modal-close {
    border-radius: 300px;
    color: ${props => props.theme.colorLight};
    cursor: pointer;
    font-size: 24px;
    padding: 4px;
    position: absolute;
    top: 4px;
    transition: all .3s;
    right: 4px;
    z-index: ${props => props.theme.layer.z3};

    &:hover {
      color: ${props => props.theme.primary};
      background: ${props => props.theme.backgroundSecondary};
    }
  }
`

export default Modal; 