import React, { useContext, useState } from 'react';
import _ from "lodash";
import Cookies from 'js-cookie';
import { WalletContext } from '../index.js';
import styled from 'styled-components';
import Row from './table-row';

const BlankState = ({showStakingModal}) => {
  const launchModal = (e) => {
    e.preventDefault(); 
    showStakingModal(true); 
  }

  return (
    <StyledBlankstateRow>
      <td colSpan={3}>
        <h3>Create a <a onClick={launchModal} href='/'>new staking deposit</a> to get started!</h3>
      </td>
    </StyledBlankstateRow>
  );
};

const StakingTable = ({showStakingModal}) => {
  const { info } = useContext(WalletContext);
  const {stakeCount, stakes} = info;

  // GET VIEW FROM COOKIE IF IT EXISTS
  const userView = Cookies.get("view"); 
  const defaultView = userView || "active"; 
  const [currentView, changeView] = useState(defaultView); 

  const [dropdown, setDropdown] = useState(false); 
  const hasActiveRows = _.find(stakes, { 'isActive': true });
  let selectMenu = null; 

  // TOGGLE TABLE VIEW DROPDOWN
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  }

  // SET VALUE OF TABLE VIEW (ALL OR ACTIVE ROWS)
  const changeTableView = (value) => {
    changeView(value); 
    Cookies.set('view', value)
  }

  // ONLY SHOW SELECT MENU WHEN THERE ARE DEPOSITS
  if(stakeCount > 0) {
    selectMenu = (
      <StyledSelector>
        <button onClick={toggleDropdown}>
          {currentView} <span className="material-icons">expand_more</span>
        </button>

        { dropdown &&
          <ul onClick={toggleDropdown}>
            <li><button onClick={()=>{changeTableView('active')}}>Active Deposits</button></li>
            <li><button onClick={()=>{changeTableView('all')}}>All Deposits</button></li>
          </ul>                
        }
      </StyledSelector>
    )
  }
  
  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            <th>Staked</th>
            <th>Earned</th>
            <th>{selectMenu}</th>
          </tr>
        </thead>
        <tbody>
          {/* BLANKSTATE, NO ROWS */}
          {stakeCount === 0 && <BlankState showStakingModal={showStakingModal}/>}

          {/* ACTIVE VIEW NO ROWS */}
          {stakeCount > 0 && currentView === "active" && !hasActiveRows && 
            <BlankState showStakingModal={showStakingModal}/>
          }

          { stakeCount > 0 && 
            // HAS DATA, CREATE TABLE ROWS
            stakes.map((item, index) => {
              let row = null; 

              // ONLY SHOW ACTIVE ROWS WHEN FILTER SELECTED
              if(currentView === "active" && item.isActive) {
                row = <Row key={index} item={item} />;
              }

              // OTHERWISE SHOW ALL ROWS
              if(currentView === "all") {
                row = <Row key={index} item={item} />;
              }

              return row;
            })
          }
        </tbody>
      </table>
    </StyledTable>
  );
};

const StyledSelector = styled.div`
  position: relative;

  > button {
    align-items: center;
    border: 1px solid ${props => props.theme.border};
    border-radius: 2px;
    background: ${props => props.theme.background}; 
    box-sizing: border-box;
    color: ${props => props.theme.color}; 
    cursor: pointer;
    font-size: 10px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    height: 24px;
    padding: 0 8px 0 16px;
    text-transform: uppercase;
    width: 100%;

    span {
      font-size: 16px;
      opacity: .24;
    }
  }

  ul {
    border-radius: 4px;
    background: ${props => props.theme.background}; 
    box-shadow: ${props => props.theme.shadow.large}; 
    border: 1px solid ${props => props.theme.border};
    color: ${props => props.theme.color}; 
    position: absolute;
    top: 28px;
    right: 0;
    list-style-type: none;
    margin: 0;
    padding: 8px;
    min-width: 200px;
    z-index: ${props => props.theme.layer.z3};

    li {
      margin-bottom: 8px;

      &:last-child {
        margin: 0;
      }
    }

    button {
      border: 1px solid ${props => props.theme.border};
      border-radius: 2px;
      background: ${props => props.theme.background}; 
      box-sizing: border-box;
      color: ${props => props.theme.primary}; 
      cursor: pointer;
      height: 32px;
      padding: 0 16px;
      text-transform: uppercase;
      transition: all .3s;
      width: 100%;

      &:hover, &:focus {
        border: 1px solid ${props => props.theme.primary};
      }
    }
  }
`

const StyledBlankstateRow = styled.tr`
  td {
    
    padding: 40px 24px;
    text-align: center;
    vertical-align: center;

    h3 {
      color: ${props => props.theme.color};
      font-size: 16px;
      line-height: 32px;
    }

    a {
      color: ${props => props.theme.primary};
    }
  }
`

const StyledTable = styled.div`
  background: ${props => props.theme.background};
  box-shadow: ${props => props.theme.shadow.small};
  border-radius: 8px;
  color: ${props => props.theme.color};
  margin: 24px 0;
  min-height: 85%;

  @media only screen and (max-width: ${props => props.theme.mobile.phone}) {
    border-radius: 0;
    min-height: auto;
    margin: 24px 0 0 0;
  }

  table {
    width: 100%;

    thead {
      th {
        border-bottom: 1px solid ${props => props.theme.border};
        font-size: 10px;
        height: 40px;
        text-align: left;
        text-transform: uppercase;
        padding: 0 16px;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }

    tbody {
      tr:last-child td {
        border: none;
      }
    }
  }
`

export default StakingTable; 