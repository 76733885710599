import React from 'react';
import { WalletContext } from '../index.js';
import StakingForm from './staking-form';
import Modal from '../../modal.js';

const StakingModal = ({ createVisible, setCreateVisible }) => {
  return (
    <Modal
      visible={createVisible}
      onClose={() => setCreateVisible(false)}
    >
      <StakingForm WalletContext={WalletContext} onClose={() => setCreateVisible(false)} /> 
    </Modal>
  );
};

export default StakingModal; 
